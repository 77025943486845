import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { StructuredText } from "react-datocms"
import { renderRule } from "react-datocms"
import {
  isHeading,
  isParagraph,
} from "react-datocms/node_modules/datocms-structured-text-utils"
import CtaPrimary from "./CtaPrimary"

const TwoColumnProduct = ({
  tag,
  title,
  description,
  heroimage,
  cta,
  reverse,
  linkId,
  linkUrl,
  structuredData,
}) => {
  return (
    <div className="w-full">
      <div className="mx-auto max-w-screen-xl  px-4 py-6 ">
        <div
          className={`${reverse ? "flex-col-reverse lg:flex-row-reverse" : ""}
           flex w-full flex-col items-center justify-center gap-8 lg:flex-row`}
        >
          <div className="flex h-full flex-1  flex-col items-start space-y-4  ">
            <div className="h-full py-2">
              {tag && (
                <h4 className="font-mono text-sm font-semibold uppercase tracking-wide ">
                  {tag}
                </h4>
              )}
              <h2 className="py-2 text-5xl font-bold text-primary-600 ">
                {title}
              </h2>
            </div>
            {structuredData ? (
              <StructuredText
                data={structuredData}
                customNodeRules={[
                  renderRule(isHeading, ({ node, children, key }) => {
                    const Heading = `h${node.level}`
                    return (
                      <Heading style={{ color: "#1f304c" }} key={key}>
                        {children}
                      </Heading>
                    )
                  }),
                  renderRule(isParagraph, ({ children, key }) => {
                    return (
                      <div className="max-w-screen-sm ">
                        <p key={key}>{children}</p>
                      </div>
                    )
                  }),
                ]}
              />
            ) : (
              <p>{description}</p>
            )}
            {linkUrl && (
              <Link
                className="flex items-center justify-center rounded-[50px] bg-primary-600 px-6 py-4 font-semibold text-white hover:opacity-90"
                to={linkUrl}
              >
                {cta}
              </Link>
            )}
            {linkId && <CtaPrimary cta={cta} recordId={linkId} />}
          </div>
          {heroimage && (
            <div className="flex flex-1 flex-col items-center justify-center space-y-4 ">
              <GatsbyImage
                alt=""
                image={heroimage}
                className="object-contain"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TwoColumnProduct
