import React from "react"

import { renderRule, StructuredText } from "react-datocms"
import {
  isHeading,
  isList,
  isParagraph,
} from "react-datocms/node_modules/datocms-structured-text-utils"

import { GatsbyImage } from "gatsby-plugin-image"
import { Navigator } from "../components/LanguageHelpers/Navigator"

const ElectronicContent = ({ structuredData }) => {
  return (
    <div className="flex w-full justify-evenly bg-transparent">
      <div className="flex w-full max-w-screen-xl flex-col items-center justify-center gap-4 ">
        {structuredData ? (
          <div className="flex w-full flex-col space-y-8 py-8  px-4">
            {" "}
            <StructuredText
              data={structuredData}
              renderInlineRecord={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsElectronic":
                    return (
                      <Navigator recordId={record.id}>{record.title}</Navigator>
                    )
                  default:
                    return null
                }
              }}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsFeaturelistinline":
                    return (
                      <div className="my-12 flex w-full flex-col justify-evenly gap-8 lg:flex-row">
                        {" "}
                        <div className="flex flex-col space-y-4 md:items-center md:justify-center">
                          {record.feature1[0].icon &&
                          record.feature1[0].icon.gatsbyImageData ? (
                            <GatsbyImage
                              alt=""
                              image={record.feature1[0].icon.gatsbyImageData}
                              style={{
                                objectFit: "contain",
                                height: "64px",
                                width: "64px",
                              }}
                            />
                          ) : (
                            <img
                              alt=""
                              src={record.feature1[0].icon?.url}
                              style={{
                                height: "64px",
                                width: "64px",
                                marginBottom: "10px",
                                filter:
                                  " brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(2884%) hue-rotate(169deg) brightness(98%) contrast(91%)",
                              }}
                            />
                          )}
                          <h2 className="text-2xl font-bold text-primary-600">
                            {record.feature1[0].title}
                          </h2>
                          {record.feature1[0].description && (
                            <p>{record.feature1[0].description}</p>
                          )}
                          {record.feature1[0]?.content?.value && (
                            <StructuredText
                              data={record.feature1[0]?.content}
                            />
                          )}
                        </div>{" "}
                        <div className="flex flex-col space-y-4 md:items-center md:justify-center">
                          {record.feature2[0].icon &&
                          record.feature2[0].icon.gatsbyImageData ? (
                            <GatsbyImage
                              alt=""
                              image={record.feature2[0].icon.gatsbyImageData}
                              style={{
                                objectFit: "contain",
                                height: "64px",
                                width: "64px",
                              }}
                            />
                          ) : (
                            <img
                              alt=""
                              src={record.feature2[0].icon?.url}
                              style={{
                                height: "64px",
                                width: "64px",
                                marginBottom: "10px",
                                filter:
                                  " brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(2884%) hue-rotate(169deg) brightness(98%) contrast(91%)",
                              }}
                            />
                          )}
                          <h2 className="text-2xl font-bold text-primary-600">
                            {record.feature2[0].title}
                          </h2>
                          {record.feature2[0].description && (
                            <p>{record.feature2[0].description}</p>
                          )}
                          {record.feature2[0]?.content?.value && (
                            <StructuredText
                              data={record.feature2[0]?.content}
                            />
                          )}
                        </div>{" "}
                        <div className="flex flex-col space-y-4 md:items-center md:justify-center">
                          {record.feature3[0].icon &&
                          record.feature3[0].icon.gatsbyImageData ? (
                            <GatsbyImage
                              alt=""
                              image={record.feature3[0].icon.gatsbyImageData}
                              style={{
                                objectFit: "contain",
                                height: "64px",
                                width: "64px",
                              }}
                            />
                          ) : (
                            <img
                              alt=""
                              src={record.feature3[0].icon?.url}
                              style={{
                                height: "64px",
                                width: "64px",
                                marginBottom: "10px",
                                filter:
                                  " brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(2884%) hue-rotate(169deg) brightness(98%) contrast(91%)",
                              }}
                            />
                          )}
                          <h2 className="text-2xl font-bold text-primary-600">
                            {record.feature3[0].title}
                          </h2>
                          {record.feature3[0].description && (
                            <p>{record.feature3[0].description}</p>
                          )}
                          {record.feature3[0]?.content?.value && (
                            <StructuredText
                              data={record.feature3[0]?.content}
                            />
                          )}
                        </div>
                      </div>
                    )
                  case "DatoCmsFeatureList":
                    return (
                      <div
                        className="grid grid-cols-1 gap-6  sm:grid-cols-2"
                        style={{ width: "100%" }}
                      >
                        {record.feature.map(feature => (
                          <div className="flex flex-col items-start justify-start gap-2">
                            {feature.icon && feature.icon.gatsbyImageData ? (
                              <GatsbyImage
                                alt=""
                                image={record.feature1[0].icon.gatsbyImageData}
                                className="h-16 w-16 object-contain"
                              />
                            ) : (
                              <img
                                alt=""
                                src={feature.icon?.url}
                                className="h-16 w-16 object-contain"
                                style={{
                                  filter:
                                    " brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(2884%) hue-rotate(169deg) brightness(98%) contrast(91%)",
                                }}
                              />
                            )}
                            <h2 className="text-2xl font-semibold text-primary-600">
                              {feature.title}
                            </h2>
                            {feature?.description && (
                              <p>{feature?.description}</p>
                            )}
                            {feature?.content?.value && (
                              <StructuredText data={feature?.content} />
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  case "DatoCmsTwocolumnwithimage":
                    return (
                      <div className="my-12 flex w-full flex-col justify-evenly lg:flex-row ">
                        <div className="flex flex-col items-center justify-center">
                          <img
                            className="max-h-[350px]"
                            src={record.image.url}
                            alt={record.image.alt}
                          />
                          <StructuredText data={record.content} />
                        </div>
                        <div className="flex flex-col items-center justify-center">
                          <img
                            className="max-h-[350px]"
                            src={record.image2.url}
                            alt={record.image2.alt}
                          />

                          <StructuredText data={record.content2} />
                        </div>
                      </div>
                    )
                  case "DatoCmsProductDetail":
                    return (
                      <div className="my-12 flex w-full flex-col justify-evenly gap-4 lg:flex-row ">
                        <div className="flex flex-1 flex-col justify-center ">
                          <h2 className="py-2 text-4xl font-bold text-primary-600">
                            {record?.title}
                          </h2>
                          <div className="flex flex-col gap-8">
                            <StructuredText
                              data={record?.description}
                              customNodeRules={[
                                renderRule(
                                  isHeading,
                                  ({ node, children, key }) => {
                                    const Heading = `h${node.level}`
                                    return (
                                      <Heading
                                        className="py-2 text-4xl font-bold text-primary-600"
                                        key={key}
                                      >
                                        {children}
                                      </Heading>
                                    )
                                  }
                                ),
                                renderRule(
                                  isParagraph,
                                  ({ node, children, key }) => {
                                    return (
                                      <div className="max-w-screen-sm ">
                                        <p key={key}>{children}</p>
                                      </div>
                                    )
                                  }
                                ),
                                renderRule(
                                  isList,
                                  ({ style, children, key }) => {
                                    return (
                                      <ul
                                        className="list-outside list-disc space-y-4 pl-4"
                                        key={key}
                                      >
                                        {children.map(item => (
                                          <li>{item}</li>
                                        ))}
                                      </ul>
                                    )
                                  }
                                ),
                              ]}
                              renderBlock={({ record }) => {
                                switch (record.__typename) {
                                  default:
                                    return
                                  case "DatoCmsCtaLink":
                                    return (
                                      <Navigator
                                        recordId={record.link.originalId}
                                        className=" flex w-full items-center justify-center rounded-full  bg-gradient-to-tr from-primary-600 to-blue-600 px-5 py-2.5 text-center text-lg font-semibold  text-white transition-all hover:opacity-90 focus:outline-none focus:ring-4 focus:ring-blue-900 md:w-fit"
                                      >
                                        {record.cta}
                                      </Navigator>
                                    )
                                }
                              }}
                              renderInlineRecord={({ record }) => {
                                switch (record.__typename) {
                                  case "DatoCmsElectronic":
                                    return (
                                      <Navigator recordId={record.id}>
                                        {record.title}
                                      </Navigator>
                                    )
                                  default:
                                    return null
                                }
                              }}
                            />
                          </div>
                        </div>{" "}
                        <div className="flex flex-1 items-center justify-center">
                          <img
                            src={record.image.url}
                            className="max-h-[350px] object-contain"
                            alt={record.image.alt}
                          />
                        </div>
                      </div>
                    )
                  case "DatoCmsTwoColumnContentImage":
                    const specs = record?.specs[0]?.specs
                    const hasImage = () => {
                      if (record.image === null) {
                        return false
                      } else return true
                    }
                    return (
                      <div
                        className={`my-6 flex w-full flex-col gap-6 lg:flex-row ${
                          hasImage()
                            ? "flex-row items-center justify-center"
                            : "flex-col items-start justify-start"
                        }`}
                      >
                        <div
                          className={`m-0 flex flex-1 flex-col items-start justify-start gap-4`}
                        >
                          {record.title && (
                            <h2 className="text-2xl font-semibold text-primary-600">
                              {record.title}
                            </h2>
                          )}
                          {record.content && (
                            <div style={{ maxWidth: "600px" }}>
                              <StructuredText
                                data={record.content}
                                renderInlineRecord={({ record }) => {
                                  switch (record.__typename) {
                                    case "DatoCmsElectronic":
                                      return (
                                        <Navigator recordId={record.id}>
                                          {record.title}
                                        </Navigator>
                                      )
                                    default:
                                      return null
                                  }
                                }}
                              />
                            </div>
                          )}
                          {record.link && (
                            <Navigator
                              className="px-6 py-3 text-white"
                              recordId={record.link.id}
                            >
                              {record.cta}
                            </Navigator>
                          )}
                        </div>
                        {record.image &&
                          (record.image?.gatsbyImageData ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flex: "1",
                              }}
                            >
                              {" "}
                              <GatsbyImage
                                image={record.image?.gatsbyImageData}
                                imgStyle={{ objectFit: "scale-down" }}
                                alt={record.image?.alt}
                              />
                            </div>
                          ) : (
                            record.image?.url && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flex: "1",
                                }}
                              >
                                {" "}
                                <img
                                  src={record.image?.url}
                                  style={{
                                    height: "150px",
                                    objectFit: "contain",
                                  }}
                                  alt={record.image?.alt}
                                />
                              </div>
                            )
                          ))}
                        {record.specs[0] && (
                          <div style={{ width: "100%" }}>
                            <div className="my-4 mx-auto flex w-fit min-w-[450px] flex-col gap-1 rounded bg-gray-100 ">
                              {Object.keys(JSON.parse(specs)).map((key, i) => (
                                <div
                                  className="flex w-full justify-center gap-3"
                                  key={key}
                                >
                                  <p className="text-base font-semibold">
                                    {key}:
                                  </p>
                                  <p>{JSON.parse(specs)[key]}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  default:
                    return null
                }
              }}
              customNodeRules={[
                renderRule(isHeading, ({ node, children, key }) => {
                  const Heading = `h${node.level}`
                  return (
                    <Heading
                      className="py-2 text-4xl font-bold text-primary-600"
                      key={key}
                    >
                      {children}
                    </Heading>
                  )
                }),
                renderRule(isParagraph, ({ node, children, key }) => {
                  return (
                    <div className="max-w-screen-sm ">
                      <p key={key}>{children}</p>
                    </div>
                  )
                }),
                renderRule(isList, ({ style, children, key }) => {
                  return (
                    <ul
                      className="list-outside list-disc space-y-4 pl-4"
                      key={key}
                    >
                      {children.map(item => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  )
                }),
              ]}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ElectronicContent
