import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { usePageLanguage } from "../hooks/usePageLanguage"
import { Navigator } from "./LanguageHelpers/Navigator"
const PageEndBanner = ({ onClick, recordId }) => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsPageEnd {
        pageEnd: nodes {
          locale
          title
          subtitle
          cta
          id: originalId
        }
      }
    }
  `)
  const {
    allDatoCmsPageEnd: { pageEnd },
  } = data
  const { pageLanguage } = usePageLanguage()
  const pageEndMatch = pageEnd.filter(({ locale }) => locale === pageLanguage)
  const { title, subtitle, cta } = pageEndMatch[0]
  return (
    <div className="mt-8 flex min-h-[350px]  w-full flex-col items-center justify-center gap-4 bg-gradient-to-r from-primary-600 to-primary-700 py-12 px-4 ">
      <h2 className="text-5xl font-bold text-white md:text-6xl ">{title}</h2>
      <p className="text-2xl text-white">{subtitle}</p>
      {recordId && (
        <Navigator
          className=" w-full rounded bg-white px-8 py-4  text-xl font-semibold text-primary-600 hover:opacity-90 sm:w-fit"
          recordId={recordId}
        >
          {cta}
        </Navigator>
      )}
      {onClick && (
        <button
          className=" w-full rounded bg-white px-8 py-4  text-xl hover:opacity-90 sm:w-fit"
          onClick={onClick}
        >
          <span className="font-semibold text-primary-600 "> {cta}</span>
        </button>
      )}
    </div>
  )
}

export default PageEndBanner
