import React, { useEffect, useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { AiOutlineDownload } from "react-icons/ai"
import InquiryModal from "./InquiryModal"

import { useDefaultLanguage } from "../hooks/useDefaultLanguage"
import { usePageLanguage } from "../hooks/usePageLanguage"
import { Navigator } from "./LanguageHelpers/Navigator"

const ProductHero = ({
  tag,
  title,
  description,
  heroimage,
  cta,
  interestText,
  dimensions,
  open,
  setOpen,
  name,
  email,
  phone,
  company,
  message,
  send,
  submit,
  close,
  retry,
  thankYou,
  error,
}) => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsDocsPage {
        DocsNodes: nodes {
          locale
          slug
        }
      }
      allDirectory {
        directory: nodes {
          name
          dir
          relativeDirectory
        }
      }
      allDatoCmsAquariusEditor {
        aquariusNodes: nodes {
          locale
          id: originalId
        }
      }
    }
  `)
  const {
    allDatoCmsDocsPage: { DocsNodes },
    allDirectory: { directory },
    allDatoCmsAquariusEditor: { aquariusNodes },
  } = data
  const { pageLanguage } = usePageLanguage()
  const { defaultLanguage } = useDefaultLanguage()

  const docsNodeMatch = DocsNodes.filter(
    ({ locale }) => locale === pageLanguage
  )
  const aquariusNodeMatch = aquariusNodes.filter(
    ({ locale }) => locale === pageLanguage
  )

  const getUrl = url => {
    if (pageLanguage === defaultLanguage) {
      return `/${url}#${title}`
    } else {
      return `/${pageLanguage}/${url}#${title}`
    }
  }
  const checkForManual = () => {
    directory.map(dir => {
      switch (dir.name) {
        case title:
          setManual(true)
          break
        default:
      }
    })
  }
  const [hasManual, setManual] = useState(false)

  useEffect(() => {
    checkForManual()
  }, [hasManual])

  return (
    <div className="flex w-full justify-center bg-lightGray p-4">
      <div className="flex w-full max-w-screen-xl flex-col gap-8 lg:flex-row">
        <div className="flex items-center justify-center lg:min-h-[450px] lg:flex-1 lg:justify-center ">
          <GatsbyImage alt="" image={heroimage} className="object-cover" />
        </div>
        <div className="flex flex-col items-start justify-center gap-6 lg:flex-1">
          <div>
            <span className="font-mono font-semibold uppercase text-deepblue ">
              {tag}
            </span>
            <h1 className="py-2 text-6xl font-bold tracking-tighter text-primary-600">
              {title}
            </h1>
          </div>
          <p className="text-xl">{description}</p>
          {dimensions && <p className="text-xl">Dimensions: {dimensions}</p>}
          <div className="mt-2 flex w-full flex-col gap-4 md:flex-row">
            <InquiryModal
              cta={cta}
              interestText={interestText}
              open={open}
              setOpen={() => setOpen(!open)}
              product={title}
              name={name}
              email={email}
              phone={phone}
              company={company}
              message={message}
              send={send}
              submit={submit}
              close={close}
              retry={retry}
              thankYou={thankYou}
              error={error}
            />
            {hasManual && (
              <div>
                <a
                  className="flex w-full items-center justify-center rounded-full border-2 border-primary-600 px-5 py-2.5 text-lg font-semibold text-primary-600 hover:opacity-90 focus:ring-primary-600 md:w-fit "
                  href={getUrl(docsNodeMatch[0].slug)}
                >
                  <AiOutlineDownload size={24} className="mr-2" /> Check Manuals
                </a>
              </div>
            )}
            {title === "Aquarius Touch" && (
              <Navigator
                target="_blank"
                className="flex w-full items-center justify-center rounded-full border-2 border-primary-600 px-5 py-2.5 text-lg font-semibold text-primary-600 hover:opacity-90 focus:ring-primary-600 md:w-fit "
                recordId={aquariusNodeMatch[0].id}
              >
                Edit Your Aquarius
              </Navigator>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductHero
