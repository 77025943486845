import React, { useState } from "react"

import { graphql } from "gatsby"
import ElectronicCard from "../components/ElectronicCard"
import ElectronicContent from "../components/ElectronicContent"
import Layout from "../components/layout"
import ProductHero from "../components/productHero"

import PageEndBanner from "../components/PageEndBanner"
import TwoColumnProduct from "../components/TwoColumnProduct"

const Electronic = ({ pageContext, data: { electronics, miscString } }) => {
  const [open, setOpen] = useState(false)
  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: electronics.title,
    image: electronics.hero.url,
    description: electronics.description,
    brand: {
      "@type": "Brand",
      name: "MoviLift",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
      ratingCount: "1",
    },
  }

  return (
    <Layout
      pageData={pageContext}
      seoTitle={electronics.seo?.seoTitle}
      seoDescription={electronics.seo?.seoDescription}
      seoImage={electronics.seo?.image?.seoImageUrl}
      schemaMarkup={schema}
    >
      <ProductHero
        open={open}
        setOpen={setOpen}
        dimensions={electronics.dimensions}
        gradient={true}
        description={electronics.description}
        tag={electronics.tag}
        title={electronics.title}
        cta={miscString.nodes[0]?.getInquiry}
        interestText={miscString.nodes[0]?.imInterestedIn}
        heroimage={electronics.hero.gatsbyImageData}
        reverse={true}
        manual={electronics.manual}
        name={miscString.nodes[0].name}
        email={miscString.nodes[0].email}
        company={miscString.nodes[0].companyName}
        phone={miscString.nodes[0].phoneNumber}
        message={miscString.nodes[0].message}
        send={miscString.nodes[0].send}
        submit={miscString.nodes[0].submitting}
        close={miscString.nodes[0].close}
        retry={miscString.nodes[0].retry}
        thankYou={miscString.nodes[0].thankYou}
        error={miscString.nodes[0].error}
        downloadManual={miscString.nodes[0].downloadManual}
        bottomImage
        isDark
      />
      {electronics.application && (
        <ElectronicContent structuredData={electronics.application} />
      )}

      {electronics.features && (
        <ElectronicContent structuredData={electronics.features} />
      )}
      {electronics.operatingPanel &&
        electronics.operatingPanel.map((op, index) => (
          <TwoColumnProduct
            title={op.title}
            structuredData={op.description}
            description={op.dimensions}
            heroimage={op.image?.gatsbyImageData}
            reverse={index % 2}
          />
        ))}

      {electronics?.relatedProduct[0]?.product[0] && (
        <div className="mx-auto my-4  flex w-full max-w-screen-xl flex-col">
          <h2 className="py-2 text-3xl font-semibold text-primary-600">
            {miscString.nodes[0].relatedProducts}
          </h2>
          <div className="grid w-full grid-cols-2 gap-4">
            {electronics.relatedProduct[0]?.product?.map(product => (
              <ElectronicCard electronic={product} />
            ))}
          </div>
        </div>
      )}
      <PageEndBanner onClick={() => setOpen(!open)} />
    </Layout>
  )
}

export default Electronic

export const query = graphql`
  query electronicQuery($id: String, $locale: String) {
    electronics: datoCmsElectronic(
      originalId: { eq: $id }
      locale: { eq: $locale }
    ) {
      originalId
      title
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      locale
      slug
      description
      dimensions
      tag
      manual {
        url
      }
      application {
        value
        links {
          ... on DatoCmsElectronic {
            __typename
            title
            id: originalId
          }
          __typename
          id: originalId
          title
        }
        blocks {
          ... on DatoCmsTwoColumnContentImage {
            id: originalId
            __typename
            title
            specs {
              specs
            }
            image {
              url
              gatsbyImageData(height: 500)
            }
            content {
              value
              links {
                __typename
                ... on DatoCmsElectronic {
                  __typename
                  title
                  id: originalId
                }
              }
            }
            cta
            link {
              __typename
              ... on DatoCmsCabinPage {
                id: originalId
              }
              ... on DatoCmsElectronicPage {
                id: originalId
              }
              ... on DatoCmsElectronic {
                id: originalId
              }
            }
          }

          ... on DatoCmsFeaturelistinline {
            __typename
            id: originalId
            feature1 {
              title
              icon {
                url
              }
              description
              content {
                value
              }
            }
            feature2 {
              title
              icon {
                url
              }
              content {
                value
              }
              description
            }
            feature3 {
              title
              icon {
                url
              }
              content {
                value
              }
              description
            }
          }
          ... on DatoCmsProductDetail {
            __typename
            title
            description {
              value
              links {
                ... on DatoCmsElectronic {
                  __typename
                  title
                  id: originalId
                }
                __typename
                id: originalId
                title
              }
            }
            id: originalId
            image {
              url
              alt
            }
          }
        }
      }
      features {
        value
        links {
          ... on DatoCmsElectronic {
            __typename
            title
            id: originalId
          }
          __typename
          id: originalId
          title
        }
        blocks {
          ... on DatoCmsProductDetail {
            __typename
            title
            description {
              blocks {
                ... on DatoCmsCtaLink {
                  id: originalId
                  __typename
                  cta
                  link {
                    originalId
                  }
                }
                ... on DatoCmsBrandlogo {
                  id: originalId
                  __typename
                }
              }
              value
              links {
                ... on DatoCmsElectronic {
                  __typename
                  title
                  id: originalId
                }
                __typename
                id: originalId
                title
              }
            }
            id: originalId
            image {
              url
              alt
            }
          }
          ... on DatoCmsTwocolumnwithimage {
            __typename
            id: originalId
            content {
              value
            }
            content2 {
              value
            }
            image {
              url
            }
            image2 {
              url
            }
          }
          ... on DatoCmsFeatureList {
            __typename
            id: originalId
            feature {
              title
              icon {
                url
              }
              description
            }
          }
        }
      }
      hero {
        gatsbyImageData(height: 450)
        url
      }
      operatingPanel {
        cop
        dimensions
        description {
          value
        }
        title
        image {
          gatsbyImageData(height: 300)
        }
      }
      category {
        title
        url
      }
      relatedProduct {
        product {
          id: originalId
          title
          slug
          locale
          tag
          hero {
            gatsbyImageData(height: 190)
          }
          categoryElectronic {
            treeParent {
              url
              title
              value
            }
            title
            url
          }
          category {
            title
            url
          }
        }
      }
    }

    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        getInquiry
        relatedProducts
        imInterestedIn
        email
        name
        companyName
        email
        phoneNumber
        message
        send
        submitting
        close
        retry
        thankYou
        error
        downloadManual
      }
    }
  }
`
