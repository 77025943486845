import { Dialog, Transition } from "@headlessui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { AiOutlineCheck, AiOutlineCloseCircle } from "react-icons/ai"
import { BiErrorAlt } from "react-icons/bi"
import * as Yup from "yup"
import { usePageLanguage } from "../hooks/usePageLanguage"
import CountryComboBox from "./CountryComboBox"
import Input from "./Input"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const InquiryModal = ({
  open,
  setOpen,
  cta,
  product,
  interestText,
  close,
  retry,
}) => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsMiscString {
        stringNodes: nodes {
          locale
          companyName
          email
          phoneNumber
          message
          send
          country
          fieldRequired
        }
      }
    }
  `)
  const {
    allDatoCmsMiscString: { stringNodes },
  } = data
  const { pageLanguage } = usePageLanguage()
  const stringNodesMatch = stringNodes.filter(
    ({ locale }) => locale === pageLanguage
  )
  const [submitText, setSubmitText] = useState(null)
  const [submitStatus, setSubmitStatus] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState()

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const RetryInquiry = () => {
    setSubmitStatus(null)
    setSubmitText(null)
  }

  const validationScheme = Yup.object({
    Company: Yup.string().required(),
    Email: Yup.string().email().required(),
    Country: Yup.string().required(),
    Phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    Message: Yup.string().required(),
  })
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationScheme),
    mode: "onChange",
    defaultValues: {
      Company: "",
      Email: "",
      Country: "",
      Phone: "",
      Message: "",
    },
  })

  const onSubmit = async (data, e) => {
    setSubmitStatus(true)
    setSubmitText("Submitting..")
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Electronic",
        subject: `${data.Company} | ${data.Country} ${selectedCountry.phone} | ${product}`,
        ...data,
      }),
    })
      .then(() => {
        setSubmitStatus(true)
        setSubmitText(
          "Thank you for reaching out, we'll be contacting you shortly"
        )
        reset()
      })
      .catch(error => {
        setSubmitStatus(false)
        setSubmitText(
          "There was an error with your submission, please email us using the address below."
        )
      })
  }
  const submitState = submitText => {
    if (submitStatus != null) {
      if (submitStatus === false) {
        //Failed submission
        return (
          <>
            <div className="flex flex-col items-center justify-center gap-4">
              <BiErrorAlt fill="red" size={45} />
              <p>{submitText}</p>
              <a href="mailto:marketing@movilift.com">
                <h4>marketing@movilift.com</h4>
              </a>
            </div>{" "}
            <button
              className="rounded-full bg-primary-600 px-2.5 py-4 text-lg font-semibold text-white"
              onClick={RetryInquiry}
            >
              {retry}
            </button>
          </>
        )
      } else {
        return (
          //Success submission
          <>
            <div className="flex flex-col items-center justify-center gap-4">
              {" "}
              <AiOutlineCheck fill="green" size={45} />
              <p>{submitText}</p>
            </div>
            <button
              className="rounded-full bg-primary-600 px-2.5 py-4 text-lg font-semibold text-white"
              onClick={setOpen}
            >
              {close}
            </button>
          </>
        )
      }
    } else {
      return (
        //Bot detected
        <>
          <div className="flex flex-col items-center justify-center gap-4">
            <p>{submitText}</p>
          </div>{" "}
          <button
            className="rounded-full bg-primary-600 px-2.5 py-4 text-lg font-semibold text-white"
            onClick={RetryInquiry}
          >
            Retry
          </button>
        </>
      )
    }
  }

  return (
    <>
      <button
        className="rounded-full bg-primary-600 px-5 py-2.5 text-xl font-semibold tracking-tight text-white shadow transition-all hover:opacity-90  "
        onClick={setOpen}
      >
        {cta}
      </button>
      <form
        className="hidden  flex-col p-4"
        name="Electronic"
        method="POST"
        data-netlify="true"
        netlify-honeypot="BotField"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="hidden"
          name="subject"
          value="{{Company}} Electronic | {{Country}}"
        />

        <input type="hidden" name="form-name" value="Electronic" />
        <input type="hidden" name="product" value={`${product}`} />
        <p style={{ display: "none" }}>
          <label>
            Don’t fill this out if you expect to hear from us!
            <input name="BotField" value="" readOnly />
          </label>
        </p>

        <input
          name="Company"
          type={"text"}
          errors={errors}
          control={control}
          label={stringNodesMatch[0].companyName}
          errorText={stringNodesMatch[0].fieldRequired}
        />
        <input
          name="Email"
          errors={errors}
          type={"email"}
          control={control}
          label={stringNodesMatch[0].email}
          errorText={stringNodesMatch[0].fieldRequired}
        />
        <input
          errors={errors}
          control={control}
          name="Country"
          label="Choose a country"
        />
        <input
          name="Phone"
          errors={errors}
          control={control}
          label={stringNodesMatch[0].phoneNumber}
          type="tel"
          inputProps={{ maxLength: "15", minLength: "9" }}
          errorText={stringNodesMatch[0].fieldRequired}
        />
        <textarea
          name="Message"
          type={"text"}
          label={stringNodesMatch[0].message}
          control={control}
          errors={errors}
          errorText={stringNodesMatch[0].fieldRequired}
          inputProps={{ maxlength: "5000" }}
        ></textarea>
        <button onClick={handleSubmit(onSubmit)}>
          {stringNodesMatch[0].send}
        </button>
      </form>
      <Transition appear show={open} as={Fragment}>
        <Dialog onClose={setOpen} className="relative z-40 my-4 ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          </Transition.Child>
          <div className="fixed inset-0 flex items-center justify-center  overflow-y-auto ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative flex h-fit  max-h-screen w-full  max-w-screen-sm  flex-col overflow-y-auto rounded-lg  bg-white     p-4 align-middle  shadow-lg  ">
                <AiOutlineCloseCircle
                  size={32}
                  onClick={() => setOpen(false)}
                  className="absolute right-4 top-4 cursor-pointer text-primary-600 "
                />
                <div className="mt-4 flex w-full flex-col items-start justify-start pb-2 ">
                  <span className="text-xl">{interestText + " "}</span>{" "}
                  <span className=" text-2xl font-bold text-primary-600">
                    {product}
                  </span>
                </div>

                {submitText !== null ? (
                  <div className="flex w-full flex-col gap-4 p-4">
                    {submitState(submitText)}
                  </div>
                ) : (
                  <form
                    name="Electronic"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="BotField"
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col gap-4"
                  >
                    <input
                      type="hidden"
                      name="subject"
                      value="{{Company}} Electronic | {{Country}}"
                    />
                    <input type="hidden" name="form-name" value="Electronic" />
                    <input type="hidden" name="product" value={`${product}`} />
                    <p style={{ display: "none" }}>
                      <label>
                        Don’t fill this out if you expect to hear from us!
                        <input name="BotField" value="" readOnly />
                      </label>
                    </p>
                    <Input
                      grayLabel
                      name="Company"
                      errors={errors.Company}
                      register={register}
                      label={stringNodesMatch[0].companyName}
                      errorsMessage={stringNodesMatch[0].fieldRequired}
                    />
                    <Input
                      grayLabel
                      name="Email"
                      errors={errors.Email}
                      register={register}
                      label={stringNodesMatch[0].email}
                      errorsMessage={stringNodesMatch[0].fieldRequired}
                    />
                    <div>
                      <label
                        className="mb-2 text-sm font-medium capitalize text-gray-500 "
                        for="message"
                      >
                        {stringNodesMatch[0].country}
                      </label>
                      <Controller
                        name="Country"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <CountryComboBox
                            placeholder={stringNodesMatch[0].country}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={e => {
                              onChange(e.name)
                              setSelectedCountry(e)
                            }}
                          />
                        )}
                      />
                      {errors.Country && (
                        <p className="mt-2 block text-sm font-medium text-red-500">
                          {stringNodesMatch[0].fieldRequired}
                        </p>
                      )}
                    </div>
                    <Input
                      grayLabel
                      name="Phone"
                      label={stringNodesMatch[0].phoneNumber}
                      errors={errors.Phone}
                      errorsMessage={stringNodesMatch[0].fieldRequired}
                      register={register}
                    />
                    <div className="md:col-span-2">
                      <label
                        className="pb-2 text-sm font-medium text-gray-500"
                        for="message"
                      >
                        {stringNodesMatch[0].message}
                      </label>
                      <textarea
                        className="block w-full resize-none rounded-lg border border-gray-300 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                        name="message"
                        placeholder={stringNodesMatch[0].message}
                        maxLength="5000"
                        {...register("Message")}
                      ></textarea>{" "}
                      {errors.Message && (
                        <p className="mt-2 block text-sm font-medium text-red-500">
                          {stringNodesMatch[0].fieldRequired}
                        </p>
                      )}
                    </div>
                    <button
                      className="mx-auto w-full max-w-[200px] rounded-full bg-primary-600 px-4 py-2 text-lg font-semibold text-white hover:opacity-90"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {stringNodesMatch[0].send}
                    </button>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default InquiryModal
