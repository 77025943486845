import React, { useState } from "react"
import { Navigator } from "./LanguageHelpers/Navigator"
import { AiOutlineRight, AiOutlineArrowRight } from "react-icons/ai"

const CtaPrimary = ({ cta, recordId }) => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(true)
  }

  const onLeave = () => {
    setHover(false)
  }
  return (
    <Navigator
      recordId={recordId}
      type="button"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      className="flex items-center justify-center gap-1 rounded-[50px] bg-primary-600 px-3 py-2 font-semibold text-white transition hover:bg-primary-700 sm:px-6 sm:py-4"
    >
      <span> {cta}</span>
      {hover ? (
        <AiOutlineArrowRight className="font-bold" />
      ) : (
        <AiOutlineRight />
      )}
    </Navigator>
  )
}

export default CtaPrimary
